import {
  ILogicTypeHideControl,
  ILogicTypeReadOnly,
} from "../IRequestModel/ILogicModel";
import { IMemoDetailModel } from "../IRequestModel/IMemoDetailModel";
import { IUserModel } from "../IRequestModel/IUserModel";
import { LogicOperatorType, LogicType } from "../constants/logic-type";
import { getDefValueInTemplate, getRefInforValue } from "./RequestScreenHelper";
export type advanceFormItemType = {
  label: string;
  type: string;
  items?: string[];
  columns?: { label: string; type: string; isInTable: boolean }[];
};

export const enumToObject = (
  emun: Record<string, string>
): { [key: string]: string } => {
  const readableLabels: { [key: string]: string } = Object.fromEntries(
    Object.entries(emun).map(([key, value]) => [
      value,
      key.replace(/([a-z])([A-Z])/g, "$1 $2"),
    ])
  );
  return readableLabels;
};

export const getTranFormAdvanceForm = (advanceForm: {
  items: any[];
}): advanceFormItemType[] => {
  let result: {
    label: string;
    type: string;
    items?: string[];
    columns?: any[];
  }[] = [];
  advanceForm.items.forEach((item) => {
    const layout = item.layout;
    layout.forEach((control: any) => {
      const temp = control.template;
      if (temp.type === "em") {
        return;
      }

      if (temp.type === "dd") {
        result.push({
          label: temp.label,
          type: temp.type,
          items: temp.attribute.items.map((e: any) => e.item),
        });
      } else if (temp.type === "tb") {
        result.push({
          label: temp.label,
          type: temp.type,
          columns: temp.attribute.column.map((e: any) => {
            return {
              label: e.control.template.label,
              type: e.control.template.type,
            };
          }),
        });
      } else {
        result.push({ label: temp.label, type: temp.type });
      }
    });
  });

  return result;
};

export const checkIsCorrect = (
  aValue: string | number,
  operator: LogicOperatorType,
  bValue: string | number
): boolean => {
  switch (operator) {
    case LogicOperatorType.Equal:
      return aValue === bValue;
    case LogicOperatorType.MoreThan:
      return Number(aValue) > Number(bValue);
    case LogicOperatorType.LessThan:
      return Number(aValue) < Number(bValue);
    case LogicOperatorType.GreaterThanAndEqual:
      return Number(aValue) >= Number(bValue);
    case LogicOperatorType.LessThanAndEqual:
      return Number(aValue) <= Number(bValue);
    case LogicOperatorType.NotEqual:
      return aValue !== bValue;
    case LogicOperatorType.Contain:
      return String(aValue).includes(String(bValue));
    case LogicOperatorType.StartWith:
      return String(aValue).startsWith(String(bValue));
    case LogicOperatorType.EndWith:
      return String(aValue).endsWith(String(bValue));
    default:
      return false;
  }
};

export const implementValueWithJsonAction = (
  layout: any,
  jsonAction: string
) => {
  switch (jsonAction) {
    case "readonly":
      layout.isReadOnly = true;
      break;
    case "hide":
      layout.isShow = false;
      break;
    case "editable":
      layout.isEditable = true;
      layout.isReadOnly = false;
      break;

    default:
      break;
  }
};

export const isRefInfo = (compareWith: string) => {
  const refInfos = [
    "Requestor_Employee_code",
    "Requestor_NameEn",
    "Requestor_NameTh",
    "Requestor_PositonEn",
    "Requestor_PositonTh",
    "Requestor_DivisionEn",
    "Requestor_DivisionTh",
    "Requestor_DepartmentEn",
    "Requestor_DepartmentTh",
    "Requestor_Email",
    "Actor_Employee_code",
    "Actor_NameEn",
    "Actor_NameTh",
    "Actor_PositonEn",
    "Actor_PositonTh",
    "Actor_DivisionEn",
    "Actor_DivisionTh",
    "Actor_DepartmentEn",
    "Actor_DepartmentTh",
    "Actor_Email",
  ];

  return refInfos.includes(compareWith);
};

export const processHideControlLogic = (
  jsonValue: ILogicTypeHideControl | ILogicTypeReadOnly,
  _control: any,
  updateTo: string,
  memoDetail: IMemoDetailModel,
  userData: IUserModel,
  controlValue?: any,
  controlTemplate?: any
) => {
  let isTable = false;
  let isConditionSatisfied = false;
  try {
    let fieldsToCheck1: any = {};

    jsonValue.conditions.forEach((maincondition: any) => {
      let _con = maincondition;
      _con.conditions.forEach((subCondition: any) => {
        try {
          if (typeof subCondition.field === "string") {
            subCondition.field = JSON.parse(subCondition.field);
          }
        } catch (error) {
          subCondition.field = subCondition.field;
        }

        let checkValue = controlValue ? controlValue : _con.value;
        if (_con.comparewith) {
          _control.forEach((control: { layout: any[] }) => {
            control.layout.forEach((layout) => {
              if (
                layout.template.label === _con.comparewith ||
                isRefInfo(_con.comparewith || "")
              ) {
                checkValue = getRefInforValue(
                  _con.comparewith || "",
                  memoDetail,
                  userData,
                  layout.data.value
                );
              }
            });
          });
        }

        _control.forEach((control: { layout: any[] }) => {
          control.layout.forEach((layout) => {
            if (layout.template.label === subCondition.field) {
              fieldsToCheck1[subCondition.field] = layout.data.value
                ? layout.data.value
                : getDefValueInTemplate(layout.template);
            }
          });
        });
        subCondition.Value = checkValue;
      });
    });

    isConditionSatisfied = isConditionSatisfiedFunc2(fieldsToCheck1, jsonValue);

    const objLabel: { label: string; type: string; isInTable: string } =
      JSON.parse(jsonValue.label);

    if (objLabel?.isInTable && controlTemplate?.type === "tb") {
      isTable = true;
    } else {
      _control.forEach((control: { layout: any[] }) => {
        control.layout.forEach((layout: any) => {
          if (layout.template.label === objLabel.label) {
            if (updateTo === "isShow") {
              layout.isShow = !isConditionSatisfied;
            } else {
              layout.isReadOnly = isConditionSatisfied;
            }
            if (isConditionSatisfied && jsonValue.resetonaction) {
              layout.data.value = getDefValueInTemplate(layout.template);
            }
          } else if (layout.template.label === objLabel.isInTable) {
            layout.template.attribute.column.map(
              (e: { label: string; isShow: boolean; isReadOnly: boolean }) => {
                if (e.label === objLabel.label) {
                  if (updateTo === "isShow") {
                    e.isShow = !isConditionSatisfied;
                  } else {
                    e.isReadOnly = isConditionSatisfied;
                  }
                }
              }
            );
          }
        });
      });
    }
  } catch (error) {
    console.error(error);
  }

  return isTable;
};

export function isConditionSatisfiedFunc(
  controlLabel: string,
  value: any,
  jsonValue: ILogicTypeHideControl | ILogicTypeReadOnly
) {
  try {
    for (let condition of jsonValue?.conditions) {
      for (let cond of condition.conditions) {
        if (cond.field === controlLabel) {
          if (checkIsCorrect(value, cond.operator, cond.value)) {
            return true;
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

export const isConditionSatisfiedFunc2 = (
  fields: { [x: string]: string | number },
  data: any
) => {
  for (let conditionGroup of data.conditions) {
    if (areSubConditionsSatisfied(fields, conditionGroup.conditions)) {
      return true;
    }
  }
  return false;
};

const areSubConditionsSatisfied = (
  fields: { [x: string]: string | number },
  data: any
) => {
  for (let cond of data) {
    if (!checkIsCorrect(fields[cond.field], cond.operator, cond.value)) {
      return false;
    }
  }
  return true;
};
